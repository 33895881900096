<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon [routerLink]="'/banner'" src="assets/svg/arrow.svg"></svg-icon>
            <h1 class="page-title">Banner</h1>
        </div>

        <div class="row my-3">
            <!--TITLE-->
            <div class="col-4">
                <div class="input-material">
                    <input id="titleBanner" class="form-control" type="text"
                           required  maxlength="150" [(ngModel)]="modelBanner.title"/>
                    <label for="titleBanner">Titulo</label>
                </div>
            </div>
            <!--LINK-->
            <div class="col-4">
                <div class="input-material">
                    <input id="linkBanner" class="form-control" type="text"
                           maxlength="255"  [(ngModel)]="modelBanner.pathLink"/>
                    <label for="linkBanner">Link</label>
                </div>
            </div>
            <!-- HIGHLIGTH DROPDOWN -->
            <div class="col-2">
                <div class="input-material flex-grow-1">
                    <input id="orderBanner" class="form-control" type="number"  [(ngModel)]="modelBanner.position"/>
                    <label for="orderBanner" class="mb-0 mr-3">Ordem</label>
                </div>
            </div>
            <div class="col-2">
                <div class="input-material flex-grow-1">
                    <select id="profileUser" class="form-control" name="type" [(ngModel)]="modelBanner.highlight" >
                        <option selected
                                [ngValue]="true">Sim</option>
                        <option [ngValue]="false">Não</option>
                    </select>
                    <label for="profileUser" class="mb-0 mr-3">Destaque</label>
                </div>
            </div>
            <!--PROFILE-->
            <!--<div class="col-12">
                <div class="card p-3 shadow-none mb-4">
                    <label>Perfil</label>
                    <div class="row mx-0">


                        <div class="col-auto px-2 mb-2" *ngFor="let item of this.profiles">
                            <div class="custom-control custom-checkbox mb-0">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="item.checked"
                                       [id]="item.id"
                                       [disabled]="item.id === 'MA'">
                                <label class="custom-control-label" [for]="item.id">{{item.name}}</label>
                            </div>
                        </div>


                    </div>
                </div>
            </div>-->

            <div class="col-12">
                <div class="card p-3 shadow-none mb-4">
                    <label class="row mx-0 mb-0">Agendar Banner
                        <div class="custom-control custom-switch ml-3 mb-0">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="modelBanner.scheduled">
                            <label class="custom-control-label" for="customSwitch1"></label>
                        </div>
                    </label>
                    <div class="row mt-3" [ngClass]="{'d-none' : !modelBanner.scheduled}">
                        <div class="col-6">
                            <div class="input-material">
                                <input id="start" class="form-control js-dateStart" type="text" autocomplete="off" maxlength="10"
                                    mask="00/00/0000" required/>
                                <label for="start">Inicio</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-material">
                                <input id="end" class="form-control js-dateEnd" type="text" autocomplete="off" maxlength="10"
                                       mask="00/00/0000" required/>
                                <label for="end">Fim</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card p-3 shadow-none mb-4">
                    <label class="row mx-0 mb-0">Imagem</label>
                    <div class="row mt-3">
                        <div (click)="showModal('modalCropBanner')" class="col-12 mt-3 d-flex justify-content-center align-items-center flex-column">
                            <div *ngIf="isNullOrUndefined(croppedImage) && isNullOrUndefined(modelBanner.pathImage)" class="d-flex justify-content-center align-items-center flex-column">
                                <b class="text-primary" href="#">Adicionar Foto</b>
                                <svg-icon class="svg-icon-primary" src="assets/svg/image.svg"></svg-icon>
                                <small>1375px x 250px</small>
                            </div>
                            <img *ngIf="!isNullOrUndefined(modelBanner.pathImage) || !isNullOrUndefined(croppedImage)"
                                 src="{{!croppedImage ? getImageUrl(modelBanner.pathImage) : croppedImage}}" alt="Banner" />
                        </div>
                    </div>
                </div>
            </div>

            <button class="btn btn-block btn-success text-white mt-3" (click)="handlerSaveBanner()">Salvar</button>
        </div>
    </div>
</div>
<app-crop-image [cropId]="'modalCropBanner'"
                [cropAspectRatio]="22/4"
                [cropResizeToWidth]="1920"
                [cropResizeToHeight]="360"
                [cropMaintainAspectRatio]="true"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>
