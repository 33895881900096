<header class="header d-flex justify-content-between align-items-center">
  <span class="d-flex d-lg-none d-flex justify-content-center align-items-center" (click)="actionNavToggle()">
    <div class="hamburger d-flex d-lg-none">
      <span class="bar"></span>
    </div>
  </span>
    <img class="logo-header" src="assets/images/logo.png">
    <div class="d-none d-lg-flex">
        <a href="#" class="d-none d-lg-block header__user">
            <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
            <!-- <img src="{{!user?.photo ? 'https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg' : user?.photo}}"
                 class="profile_photo"/> -->
        </a>
        <!--<div class="header__btn">
            <app-notification></app-notification>
        </div>
        <div class="header__btn pr-0">
            <font class="header_font">{{'language' | translate}}:</font>
            <a class="header_font" href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'pt'}"
               (click)="switchLanguage('pt')">PT</a>
            <span class="separator">|</span>
            <a class="header_font" href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'es'}"
               (click)="switchLanguage('es')">ES</a>
        </div>-->
        <div class="header__btn">
            <a href="javascript:void('sair');" [routerLink]="['/logout']" class="d-none d-lg-flex header__user__outbtn">
                <strong class="header__user__outbtn__text">{{'exit' | translate}}</strong>
            </a>
        </div>
    </div>
</header>
